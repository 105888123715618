// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "B_sC d_bD";
export var storyRowWrapper = "B_hx d_hx d_bK";
export var storyLeftWrapper = "B_sD d_bz d_bP";
export var storyWrapperFull = "B_sF d_cD";
export var storyWrapperFullLeft = "B_qZ d_cD d_bz d_bP d_bD";
export var contentWrapper = "B_mS d_hy";
export var storyLeftWrapperCenter = "B_sG d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "B_sH d_hF";
export var storyHeader = "B_sJ d_hD d_w d_cs";
export var storyHeaderCenter = "B_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "B_hB d_hB d_by d_dw";
export var storyBtnWrapper = "B_sK d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "B_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "B_rj d_fg d_Z";
export var imageWrapperFull = "B_sL d_w d_H d_bf d_Z";
export var SubtitleSmall = "B_pd D_pd D_sN D_sZ";
export var SubtitleNormal = "B_pf D_pf D_sN D_s0";
export var SubtitleLarge = "B_pg D_pg D_sN D_s1";
export var textLeft = "B_dv";
export var textCenter = "B_dw";
export var textRight = "B_dx";
// extracted by mini-css-extract-plugin
export var customText = "w_sf d_dv d_cs d_cg";
export var videoIframeStyle = "w_nS d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "w_rh d_cs d_cg d_Z";
export var customRow = "w_pb d_bD d_bf";
export var quoteWrapper = "w_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "w_nX d_H";
export var masonryImageWrapper = "w_n3";
export var title = "w_sg";
export var Title3Small = "w_sh D_sh D_sN D_sW";
export var Title3Normal = "w_sj D_sj D_sN D_sX";
export var Title3Large = "w_sk D_sk D_sN D_sY";